import {
    SelectOption,
    TBFormComponent,
    TBInnerComponent,
    TBRoutesComponent,
    TBRoutesComponentEditor,
    TBRoutesExtendedGenerator,
    TBTemplateCheckboxesData,
    TBTemplateColumnData,
    TBTemplateComponent,
    TBTemplateCTAData,
    TBTemplateDropdownData,
    TBTemplateElementDataBlock,
    TBTemplateFormButtonData,
    TBTemplateFormData,
    TBTemplateHRData,
    TBTemplateHTMLData,
    TBTemplateImageData,
    TBTemplateRadioButtonsData,
    TBTemplateRowData,
    TBTemplateSocialLinksData,
    TBTemplateTextAreaData,
    TBTemplateTextBoxData,
    TBTemplateTextData,
} from 'apps/template-builder/types';
import { t } from 'utils/lang';

export const getSelectOptions = (values: string[], tPrefix: string | string[]): SelectOption[] =>
    values.map((value, index) => ({
        value,
        label: t(Array.isArray(tPrefix) ? tPrefix[index] : `${tPrefix}.${value}`),
    }));

const getRoutes = <T>(root: string, extra?: TBRoutesExtendedGenerator<T>): TBRoutesComponent & T => {
    const routes: TBRoutesComponent = {
        add: (): string => `${routes.root()}/add`,
        update: (action = ':action', id = ':id'): string => `${routes.root()}/${id}/${action}`,
        list: (): string => `${routes.root()}/list`,
        listStatus: (status = ':status'): string => `${routes.list()}/${status}`,
        root: (): string => root,
    };
    return { ...routes, ...(extra ? extra(routes) : {}) } as TBRoutesComponent & T;
};

const TB_ROUTES_ROOT = '/template-builder';
function getBasicAndExtraRoutes(routes: TBRoutesComponent): TBRoutesComponentEditor {
    return {
        addBasic: (): string => `${routes.add()}/base`,
        addExtra: (): string => `${routes.add()}/extra`,
        updateBasic: (action = ':action', id = ':id'): string => `${routes.update(action, id)}/base`,
        updateExtra: (action = ':action', id = ':id'): string => `${routes.update(action, id)}/extra`,
    };
}
export const TB_ROUTES = {
    root: TB_ROUTES_ROOT,
    colourGroups: getRoutes(`${TB_ROUTES_ROOT}/colour-groups`),
    buttons: getRoutes(`${TB_ROUTES_ROOT}/buttons`),
    textStyles: getRoutes(`${TB_ROUTES_ROOT}/text-styles`),
    templates: getRoutes<TBRoutesComponentEditor>(`${TB_ROUTES_ROOT}/templates`, getBasicAndExtraRoutes),
    footers: getRoutes<TBRoutesComponentEditor>(`${TB_ROUTES_ROOT}/footers`, getBasicAndExtraRoutes),
    forms: getRoutes<TBRoutesComponentEditor>(`${TB_ROUTES_ROOT}/forms`, getBasicAndExtraRoutes),
};

export const UPDATE_ACTIONS = {
    copy: 'copy',
    edit: 'edit',
} as const;

export const SPACES_REG_EXP = /\d+ \d+ \d+ \d+/;
export const COMPONENT_NAME_REG_EXP = /^[a-z0-9_.-]*$/;

export const TB_EDITOR_STATUS = {
    BLOCKED: 'blocked',
};

export const TB_DROPPABLE_EDITOR = 'tb-editor';
export const TB_DROPPABLE_ROWS = 'tb-rows';
export const TB_DROPPABLE_COMPONENTS = 'tb-components';

export const TB_COMPONENT_STATUS = {
    active: 'active',
    archived: 'archived',
    deleted: 'deleted',
    inProgress: 'in_progress',
    shared: 'shared',
} as const;

export const TB_BLOCK_ALIGN = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
} as const;

export const TB_BLOCK_VERTICAL_ALIGN = {
    TOP: 'top',
    MIDDLE: 'middle',
    BOTTOM: 'bottom',
} as const;

export const TB_BLOCK_BACKGROUND = {
    FIXED: 'fixed',
    EDITABLE: 'editable',
} as const;

export const TB_BUTTON_SIZE = {
    large: 'large',
    medium: 'medium',
    mini: 'mini',
    small: 'small',
} as const;

export const TB_FONT_WEIGHT = {
    regular: 'regular',
    bold: 'bold',
} as const;

export const TB_TEXT_DECORATION = {
    none: 'none',
    underline: 'underline',
    overline: 'overline',
    lineThrough: 'line-through',
} as const;

export const TB_LABEL_PLACEMENTS = {
    above: 'above',
    left: 'left',
};

export const TB_IMAGE_KIND = {
    standard: 'standard',
    partnerLogo: 'partner_logo',
    clientLogo: 'client_logo',
} as const;

export const TB_TEXT_COLOUR = {
    default: 'default',
    fixed: 'fixed',
    colourGroup: 'colourGroup',
} as const;

export const TB_TEXT_KIND = {
    richText: 'rich_text',
    plainText: 'plain_text',
    predefined: 'predefined',
} as const;

export const TB_TEMPLATE_STATUS = {
    COMPLETED: 'completed',
    SHARED: 'shared',
    IN_PROGRESS: 'in_progress',
    ARCHIVED: 'archived',
} as const;

export const TB_TEMPLATE_TYPE = {
    EMAIL: 'email',
    LANDING_PAGE: 'landing_page',
} as const;

export const TB_TEMPLATE_TYPE_MAP = {
    LandingPageTemplate: TB_TEMPLATE_TYPE.LANDING_PAGE,
    EmailTemplate: TB_TEMPLATE_TYPE.EMAIL,
} as const;

export const BLOCK_ALIGN_OPTIONS = (): SelectOption[] =>
    getSelectOptions(
        [TB_BLOCK_ALIGN.LEFT, TB_BLOCK_ALIGN.CENTER, TB_BLOCK_ALIGN.RIGHT],
        'template_builder.block.align'
    );

export const BLOCK_VERTICAL_ALIGN_OPTIONS = (): SelectOption[] =>
    getSelectOptions(
        [TB_BLOCK_VERTICAL_ALIGN.TOP, TB_BLOCK_VERTICAL_ALIGN.MIDDLE, TB_BLOCK_VERTICAL_ALIGN.BOTTOM],
        'template_builder.block.vertical_align'
    );

export const BUTTON_SIZE_OPTIONS = (): SelectOption[] =>
    getSelectOptions(
        [TB_BUTTON_SIZE.large, TB_BUTTON_SIZE.medium, TB_BUTTON_SIZE.small, TB_BUTTON_SIZE.mini],
        'template_builder.buttons.size'
    );

export const FONT_WEIGHT_OPTIONS = (): SelectOption[] =>
    getSelectOptions([TB_FONT_WEIGHT.regular, TB_FONT_WEIGHT.bold], 'client.styling.font');

export const TEXT_DECORATIONS_OPTIONS = (): SelectOption[] =>
    getSelectOptions(
        [
            TB_TEXT_DECORATION.none,
            TB_TEXT_DECORATION.underline,
            TB_TEXT_DECORATION.overline,
            TB_TEXT_DECORATION.lineThrough,
        ],
        'client.styling.text_decoration'
    );

export const ITEM_STATUS_OPTIONS = (): SelectOption[] =>
    getSelectOptions(
        [TB_COMPONENT_STATUS.shared, TB_COMPONENT_STATUS.inProgress, TB_COMPONENT_STATUS.archived],
        ['general.status.active', 'template.designer_status.in_progress', 'general.status.archived']
    );

export const LABEL_PLACEMENT_OPTIONS = (): SelectOption[] =>
    getSelectOptions([TB_LABEL_PLACEMENTS.above, TB_LABEL_PLACEMENTS.left], 'template_builder.forms.label_placements');

export const TEMPLATE_TYPE_OPTIONS = (): SelectOption[] =>
    getSelectOptions([TB_TEMPLATE_TYPE.EMAIL, TB_TEMPLATE_TYPE.LANDING_PAGE], 'template_builder.templates.types');

export const TB_COMPONENT_TYPE = {
    button: 'button',
    checkboxes: 'checkboxes',
    colourGroup: 'colour-group',
    column: 'column',
    cta: 'cta',
    dropdown: 'dropdown',
    footer: 'footer',
    form: 'form',
    formButton: 'form-button',
    hr: 'hr',
    html: 'html',
    image: 'image',
    gdpr: 'gdpr',
    phoneNumber: 'phone-number',
    radioButtons: 'radio-buttons',
    row: 'row',
    socialMedia: 'social-media',
    template: 'template',
    templateForm: 'template-form',
    text: 'text',
    textArea: 'text-area',
    textBox: 'text-box',
    textStyle: 'text-style',
    sendTestEmail: 'send-test-email',
} as const;

export const TB_TEMPLATE_FOOTER_PATH = 'footer';
export const TB_TEMPLATE_FORM_PATH = 'form';

export const EMAIL_COMPONENTS: TBTemplateComponent[] = [
    TB_COMPONENT_TYPE.text,
    TB_COMPONENT_TYPE.image,
    TB_COMPONENT_TYPE.cta,
    TB_COMPONENT_TYPE.hr,
    TB_COMPONENT_TYPE.socialMedia,
];

export const LANDING_PAGE_COMPONENTS: TBTemplateComponent[] = [
    ...EMAIL_COMPONENTS,
    TB_COMPONENT_TYPE.html,
    TB_COMPONENT_TYPE.templateForm,
];

export const FORM_COMPONENTS: TBFormComponent[] = [
    TB_COMPONENT_TYPE.textBox,
    TB_COMPONENT_TYPE.textArea,
    TB_COMPONENT_TYPE.dropdown,
    TB_COMPONENT_TYPE.radioButtons,
    TB_COMPONENT_TYPE.checkboxes,
    TB_COMPONENT_TYPE.text,
    TB_COMPONENT_TYPE.formButton,
];

export const EMPTY_INNER_COMPONENT: TBInnerComponent = {
    data: {},
};

export const EMPTY_BLOCK_DATA: TBTemplateElementDataBlock = {};

export const EMPTY_ROW_DATA: TBTemplateRowData = {
    padding: '0 0 0 0',
    page: undefined,
};

export const EMPTY_COLUMN_DATA: TBTemplateColumnData = {
    flex: '1',
    padding: '0 0 0 0',
};

export const EMPTY_FORM_DATA: TBTemplateFormData = {
    formId: '',
    submitPage: '',
    colour: {
        type: TB_TEXT_COLOUR.default,
    },
    block: EMPTY_BLOCK_DATA,
};

export const EMPTY_FORM_BUTTON_DATA: TBTemplateFormButtonData = {
    text: '[BUTTON]',
    buttonType: 'submit',
    colour: {
        type: TB_TEXT_COLOUR.default,
    },
    block: EMPTY_BLOCK_DATA,
};

export const EMPTY_TEXT_DATA: TBTemplateTextData = {
    isEditable: true,
    text: '',
    textKind: TB_TEXT_KIND.richText,
    hasCharLimit: true,
    toggle: false,
    instructionalCopy: '',
    colour: {
        type: TB_TEXT_COLOUR.default,
    },
    block: EMPTY_BLOCK_DATA,
};

export const EMPTY_TEXT_BOX_DATA: TBTemplateTextBoxData = {
    block: EMPTY_BLOCK_DATA,
    fieldType: 'plain',
    isRequired: false,
    label: '[TEXT LABEL]',
    placeholder: '',
};

export const EMPTY_CHECKBOXES_DATA: TBTemplateCheckboxesData = {
    block: EMPTY_BLOCK_DATA,
    isRequired: false,
    label: '[CHECKBOXES LABEL]',
    options: [''],
};

export const EMPTY_DROPDOWN_DATA: TBTemplateDropdownData = {
    block: EMPTY_BLOCK_DATA,
    isRequired: false,
    label: '[DROPDOWN LABEL]',
    placeholder: '',
    options: ['', ''],
};

export const EMPTY_RADIOBUTTONS_DATA: TBTemplateRadioButtonsData = {
    block: EMPTY_BLOCK_DATA,
    isRequired: false,
    label: '[RADIO BUTTONS LABEL]',
    options: ['', ''],
};

export const EMPTY_GDPR_DATA: TBTemplateRadioButtonsData = {
    block: EMPTY_BLOCK_DATA,
    isRequired: true,
    label: '[GDPR LABEL]',
    options: ['yes', 'no'],
};

export const EMPTY_TEXT_AREA_DATA: TBTemplateTextAreaData = {
    block: EMPTY_BLOCK_DATA,
    isLimited: false,
    isRequired: false,
    label: '[TEXT AREA LABEL]',
    limit: 150,
    placeholder: '',
};

export const EMPTY_CTA_DATA: TBTemplateCTAData = {
    isEditable: true,
    text: '',
    isLinkEditable: true,
    toggle: false,
    instructionalCopy: '',
    link: {
        type: '',
        url: '',
    },
    colour: {
        type: TB_TEXT_COLOUR.default,
    },
    backgroundColour: {
        type: TB_TEXT_COLOUR.default,
    },
    backgroundHoverColour: {
        type: TB_TEXT_COLOUR.default,
    },
    borderColour: {
        type: TB_TEXT_COLOUR.default,
    },
    block: EMPTY_BLOCK_DATA,
};

export const EMPTY_HR_DATA: TBTemplateHRData = {
    height: 2,
    colour: {
        type: TB_TEXT_COLOUR.fixed,
        value: '#000000',
    },
    block: EMPTY_BLOCK_DATA,
};

export const EMPTY_HTML_DATA: TBTemplateHTMLData = {
    content: '',
    colour: {
        type: TB_TEXT_COLOUR.fixed,
        value: '#000000',
    },
    block: EMPTY_BLOCK_DATA,
};

export const EMPTY_IMAGE_DATA: TBTemplateImageData = {
    isEditable: true,
    toggle: false,
    instructionalCopy: '',
    height: 150,
    isLinkEditable: null,
    link: undefined,
    kind: TB_IMAGE_KIND.standard,
    block: EMPTY_BLOCK_DATA,
};

export const EMPTY_SOCIAL_LINK: TBTemplateSocialLinksData = {
    links: [],
    instructionalCopy: '',
    icon: {
        width: 32,
        height: 32,
        spacing: 20,
    },
    colour: {
        type: TB_TEXT_COLOUR.default,
    },
    block: EMPTY_BLOCK_DATA,
};
